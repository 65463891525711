import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '../../../utils/twMerge'
import './typography.css'

const pVariants = cva('', {
  variants: {
    variant: {
      typoTitle: 'typo-title',
      typoSubtitle: 'typo-title-subtitle',
      typoDrawer: 'typo-drawer',
      typoToggle: 'typo-toggle',
      typoErrorPage: 'typo-error-page',
      typoErrorPageMessage: 'typo-error-page-message',
      typoErrorUpdated: 'typo-error-updated',
      typoLoginError: 'typo-login-error',
      typoLoginLagel: 'typo-login-label',
      typoEmailSent: 'typo-email-sent',
      typoForgotPasswordTitle: 'typo-forgot-password-title',
      typoNewPasswordTitle: 'typo-new-password-title',
      typoNewPasswordText: 'typo-new-password-text',
      typoSuccessUpdated: 'typo-success-updated',
      typoHeaderTitle: 'typo-header-title',
      typoHeaderSubitle: 'typo-header-subtitle',
      typoIncidenceDate: 'typo-incidence-date',
      typoIncidenceLastUpdate: 'typo-incidence-last-update',
      typoIncidenceType: 'typo-incidence-type',
      typoIncidenceText: 'typo-incidence-text',
      typoIncidenceActionTaken: 'typo-incidence-actionTaken',
      typoIncidenceFooterName: 'typo-incidence-footer-name',
      typoIncidenceDescription: 'typo-incidence-description',
      typoIncidenceExternalId: 'typo-incidence-externalId',
      typoIncidenceNoData: 'typo-incidence-nodata',
      typoIncidenceNoDataH5: 'typo-incidence-nodata-h5',
      typoIncidenceDetailsLabel: 'typo-incidence-details-label',
      typoIncidenceDetailsInfo: 'typo-incidence-details-info',
      typoIncidenceDetailsCommonScheduled: 'typo-incidence-details-common-scheduled',
      typoIncidenceDetailsHeader: 'typo-incidence-details-header',
      typoIncidenceDetailsMiniCard: 'typo-incidence-details-mini-card',
      typoChatUITitle: 'typo-chat-ui-title',
      typoChatUIMessage: 'typo-chat-ui-message',
      typoFiltersTitle: 'typo-filters-title',
      typoFiltersApply: 'typo-filters-apply',
      typoFiltersClear: 'typo-filters-clear',
      typoSnackbar: 'typo-snackbar',
      typoSnackbarBtn: 'typo-snackbar-btn',
      typoToolbarItemText: 'typo-toolbarItem-text',
      typoSystemNoteUpdate: 'typo-systemNote-update',
      typoSystemNoteField: 'typo-systemNote-field',
      typoSystemNoteMessage: 'typo-systemNote-message',
      typoReceivedNoteUsername: 'typo-receivedNote-username',
      typoReceivedNoteCreatedAt: 'typo-receivedNote-createdAt',
      typoAdditionalDetails: 'typo-additionalDetails',
      typoChartsDailyOverview: 'typo-charts-daily-overview',
      typoChartTooltip: 'typo-chart-tooltip',
      typoChartModeText: 'typo-chart-mode-text',
      typoCriticalityChart: 'typo-criticality-chart',
      typoCriticalityLabel: 'typo-criticality-label',
      typoLegendsIncidence: 'typo-legends-incidence',
      typoTransportCardLabel: 'typo-transport-card-label',
      typoTransportCardInfo: 'typo-transport-card-info',
      typoTransportDetailsBody: 'typo-transport-details-body',
      typoTransportDetailsVTracker: 'typo-transport-details-v-tracker',
      typoTransportDetailsLabel: 'typo-transport-details-label',
      typoTransportDetailsItem: 'typo-transport-details-time',
      typoTransportDetailsCommonScheduled: 'typo-transport-details-common-scheduled',
      typoNoData: 'typo-no-data',
      typoDateShareTransportDetails: 'typo-date-share-transport-details',
      typoInfoItemTitle: 'typo-info-item-title',
      typoInfoItemValue: 'typo-info-item-value',
      typoOrderDetailsId: 'typo-order-details-id'
    }
  }
})

export interface TypographyProps extends React.HTMLAttributes<HTMLParagraphElement>, VariantProps<typeof pVariants> {
  asChild?: boolean
}

const KairaTypography = React.forwardRef<HTMLParagraphElement, TypographyProps>(
  ({ className, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'p'
    return <Comp className={cn(pVariants({ variant, className }))} ref={ref} {...props} />
  }
)
KairaTypography.displayName = 'KairaTypography'

export { KairaTypography, pVariants }
