export const SVGTriangleAlertRed = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icon / Alert'>
        <g id='Union'>
          <path
            d='M12.6321 8.90293C12.6509 8.44879 12.2879 8.07031 11.8333 8.07031C11.3788 8.07031 11.0157 8.44879 11.0346 8.90293L11.274 14.6655C11.2865 14.9654 11.5332 15.2021 11.8333 15.2021C12.1335 15.2021 12.3802 14.9654 12.3927 14.6655L12.6321 8.90293Z'
            fill='#E75147'
          />
          <path
            d='M11.2454 17.8135C11.409 17.9847 11.6049 18.0703 11.8333 18.0703C11.9877 18.0703 12.1265 18.0316 12.25 17.954C12.3765 17.8733 12.4769 17.7667 12.5509 17.6343C12.6281 17.5018 12.6667 17.3565 12.6667 17.1982C12.6667 16.9592 12.5849 16.7541 12.4213 16.5829C12.2577 16.4117 12.0617 16.3261 11.8333 16.3261C11.6049 16.3261 11.409 16.4117 11.2454 16.5829C11.0818 16.7541 11 16.9592 11 17.1982C11 17.4372 11.0818 17.6423 11.2454 17.8135Z'
            fill='#E75147'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.1421 3.57735C11.5234 2.9013 12.4766 2.9013 12.8579 3.57735L21.8658 19.5492C22.2471 20.2252 21.7705 21.0703 21.0079 21.0703H2.99209C2.22952 21.0703 1.75291 20.2252 2.13419 19.5492L11.1421 3.57735ZM12.1611 3.97035L21.169 19.9422C21.2119 20.0183 21.2087 20.0999 21.169 20.1703C21.1299 20.2397 21.0728 20.2703 21.0079 20.2703H2.99209C2.92717 20.2703 2.87014 20.2397 2.83101 20.1703C2.79134 20.0999 2.78807 20.0183 2.83101 19.9422L11.8389 3.97035C11.9141 3.83697 12.0859 3.83697 12.1611 3.97035Z'
            fill='#E75147'
          />
        </g>
      </g>
    </svg>
  )
}
