import { create } from 'zustand'

interface ISnackbarStore {
  isSnackbarOpen: boolean
  message: string
  actionMessage: string
  severity: 'success' | 'info' | 'warning' | 'error'
  setIsSnackbarOpen: (isSnackbarOpen: boolean) => void
  setMessage: (message: string) => void
  setActionMessage: (actionMessage: string) => void
  setSeverity: (severity: 'success' | 'info' | 'warning' | 'error') => void
}

export const snackbarStore = create<ISnackbarStore>((set) => ({
  isSnackbarOpen: false,
  message: '',
  actionMessage: '',
  severity: 'success',
  setIsSnackbarOpen: (isSnackbarOpen) => {
    set({ isSnackbarOpen })
  },
  setMessage: (message) => {
    set({ message })
  },
  setActionMessage: (actionMessage) => {
    set({ actionMessage })
  },
  setSeverity: (severity) => {
    set({ severity })
  }
}))
