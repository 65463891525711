import { Box, CircularProgress } from '@mui/material'

export const KairaSpinner = () => {
  return (
    <Box
      sx={{
        opacity: '0.6',
        backgroundColor: '#DDDDDD',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0px',
        left: '0px',
        zIndex: '1000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <CircularProgress />
    </Box>
  )
}
