/* eslint-disable @typescript-eslint/no-explicit-any */
import Http from '../config/axios'

export const useHttp = () => {
  const sendRequest = async (method: 'get' | 'post' | 'put' | 'delete', url: string, data?: object) => {
    return await Http[method](url, data)
  }

  return {
    get: async (url: string, params?: object) => await sendRequest('get', url, params),
    post: async (url: string, body?: object) => await sendRequest('post', url, body),
    put: async (url: string, body?: object) => await sendRequest('put', url, body),
    delete: async (url: string, body?: object) => await sendRequest('delete', url, body)
  }
}
