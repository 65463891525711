import { Box, Link } from '@mui/material'
import { SVGNotFound } from '@shared/components/icons/SVGNotFound'
import { KairaTypography } from '@shared/components/ui/typography/typography'
import { useTranslation } from 'react-i18next'

export const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        ml: { xs: '0', sm: '100px' },
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        justifyContent: { xs: 'center', sm: 'flex-start' },
        backgroundImage: 'url("/images/errorBg.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: { xs: 'center', sm: 'right' }
      }}>
      <Box>
        <SVGNotFound />
      </Box>
      <Box sx={{ ml: { xs: '0', sm: '64px' }, mt: { xs: '24px', sm: 0 } }}>
        <KairaTypography variant={'typoErrorPage'}>{t('COMMON.ERROR')} 404</KairaTypography>
        <KairaTypography variant={'typoErrorPageMessage'}>
          {t('COMMON.ERROR_MESSAGE1')} <br /> {t('COMMON.ERROR_MESSAGE2')}
        </KairaTypography>
        <Link sx={{ fontSize: '32px', color: '#2080C3' }} href='/'>
          {t('COMMON.GO_TO')}
        </Link>
      </Box>
    </Box>
  )
}
