import atlasShipmentService from '@modules/Atlas/services/atlas.service'
import { checkUserIsLoged, checkUserPermissions } from '@shared/utils/checkUser'

export const wareHouseLoader = async () => {
  checkUserIsLoged()
  checkUserPermissions('atlas.warehouse')
  const { data } = await atlasShipmentService.getAllShipments()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shipments: any = data.data
  return shipments
}
