import { Box } from '@mui/material'
import { SVGGreenCircleCheck } from '@shared/components/icons/SVGGreenCircleCheck'
import { KairaButton } from '@shared/components/ui/button/button'
import { KairaTypography } from '@shared/components/ui/typography/typography'
import { useRef, type FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

interface IEmailSentProps {
  handleSend: (event: FormEvent<HTMLFormElement>) => void
}

export const EmailSent = ({ handleSend }: IEmailSentProps) => {
  const { t } = useTranslation()
  const formRefEmailSent = useRef(null)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <SVGGreenCircleCheck />
      <KairaTypography variant='typoEmailSent'>{t('AUTH.EMAIL_SENT_TEXT')}</KairaTypography>
      <form
        ref={formRefEmailSent}
        onSubmit={(e) => {
          handleSend(e)
        }}
        style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <KairaTypography variant={'typoEmailSent'}>
          <>
            {t('AUTH.EMAIL_RESEND_TEXT')}
            <KairaButton variant={'btnEmailSent'} type='submit'>
              {t('AUTH.EMAIL_RESEND')}
            </KairaButton>
          </>
        </KairaTypography>
      </form>
    </Box>
  )
}
