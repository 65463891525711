import { Alert, Snackbar } from '@mui/material'
import { useSnackbar } from '@shared/components/snackbar/hooks/useSnackbar'
import { KairaTypography } from '../ui/typography/typography'

export const SnackBar = () => {
  const { action, message, severity, isSnackbarOpen, setIsSnackbarOpen } = useSnackbar()

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isSnackbarOpen}
      onClose={() => {
        setIsSnackbarOpen(false)
      }}
      autoHideDuration={3000}
      sx={{ width: '320px' }}>
      <Alert
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
        severity={severity}
        sx={{ width: '100%', color: 'white' }}
        variant='filled'
        action={action}>
        <KairaTypography variant={'typoSnackbar'}>{message}</KairaTypography>
      </Alert>
    </Snackbar>
  )
}
