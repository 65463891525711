import { checkUserIsLoged, checkUserPermissions } from '@shared/utils/checkUser'

export const adminLoader = async () => {
  checkUserIsLoged()
  // TODO:
  // permisos admin?
  checkUserPermissions('atlas.admin.shipper')
  // llamada de datos de back
  // const shippers: any = data.data
  // return shippers
  return true
}
