export const SVGGreenCircleCheck = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='65' height='65' viewBox='0 0 65 65' fill='none'>
      <path
        d='M47.585 19.3022C48.0728 18.6535 49.0082 18.5533 49.646 19.0168C50.3114 19.5004 50.4181 20.4469 49.9497 21.0904L49.926 21.1231L29.7285 43.0674C29.4795 43.363 29.1284 43.5863 28.7126 43.6309L28.6592 43.6844H28.5141C28.0313 43.6844 27.5752 43.4 27.3329 43.0541L16.9138 31.6013L16.8992 31.5794C16.4186 30.859 16.6727 29.9747 17.3017 29.5175L17.3133 29.5091C18.0266 29.0339 18.9007 29.2778 19.3622 29.8926L28.6031 39.6986L47.585 19.3022Z'
        fill='#78E278'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.8503 64.3278C50.2755 64.1398 64.375 49.9347 64.375 32.4774C64.375 14.9032 50.0862 0.625 32.5 0.625C14.9138 0.625 0.625 14.9032 0.625 32.4774C0.625 50.0509 14.9132 64.375 32.5 64.375H32.8503V64.3278ZM32.5 3.58863C16.5685 3.58863 3.59026 16.5579 3.59026 32.4774C3.59026 48.3968 16.5685 61.3661 32.5 61.3661C48.4315 61.3661 61.4097 48.3968 61.4097 32.4774C61.4097 16.5576 48.4764 3.58863 32.5 3.58863Z'
        fill='#78E278'
      />
    </svg>
  )
}
