export const SVGEyeVisible = () => {
  return (
    <svg width='20' height='12' viewBox='0 0 20 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.94587 11.7697C6.52472 11.7697 3.20462 9.86424 0.0866113 6.11108C-0.0288704 5.98117 -0.0288704 5.77907 0.0866113 5.64916C3.30567 1.78052 6.74125 -0.110496 10.2779 0.00498551C15.9365 0.207079 19.7763 5.44706 19.935 5.66359C20.0217 5.79351 20.0217 5.9523 19.935 6.08221C19.7763 6.29874 15.9544 11.582 10.2958 11.7697C10.1803 11.7697 10.0891 11.7697 9.97361 11.7697H9.94587ZM0.822808 5.88012C3.83977 9.41675 7.00108 11.1634 10.2346 11.0335C14.9116 10.8747 18.4049 6.84728 19.17 5.88012C18.3905 4.91296 14.9116 0.885534 10.2346 0.726747C7.01552 0.611265 3.83977 2.35793 0.822808 5.88012Z'
        fill='#dddddd'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.0036 10.0385C7.69398 10.0385 5.8174 8.16191 5.8174 5.85228C5.8174 3.54264 7.69398 1.66606 10.0036 1.66606C12.3132 1.66606 14.1898 3.54264 14.1898 5.85228C14.1898 8.16191 12.3132 10.0385 10.0036 10.0385ZM10.0036 2.40226C8.09816 2.40226 6.53916 3.96126 6.53916 5.86671C6.53916 7.77216 8.09816 9.33117 10.0036 9.33117C11.9091 9.33117 13.4681 7.77216 13.4681 5.86671C13.4681 3.96126 11.9091 2.40226 10.0036 2.40226Z'
        fill='#dddddd'
      />
    </svg>
  )
}
