import Http from '../../../core/config/axios'
import { type IEta } from '../interfaces/shipment-masters.interface'

class AtlasShipmentService {
  private static instance: AtlasShipmentService

  public static getInstance(): AtlasShipmentService {
    if (!AtlasShipmentService.instance) {
      AtlasShipmentService.instance = new AtlasShipmentService()
    }
    return AtlasShipmentService.instance
  }

  // GET

  public async getAllShipments() {
    const data = await Http.get('/atlas/shipment')
    return data
  }

  public async getShipmentMasters(shipmentId: string) {
    const data = await Http.get(`/atlas/shipment/${shipmentId}`)
    return data
  }

  public async getAllPosByShipmentId(shipmentId: string, search = '', containerId = '') {
    const data = await Http.get(`/atlas/shipment/${shipmentId}/po?search=${search}&containerId=${containerId}`)
    return data
  }

  public async getPoByShipmentId(shipmentId: number, poId: number) {
    const data = await Http.get(`/atlas/shipment/${shipmentId}/po/${poId}`)
    return data
  }

  public async getContainerType() {
    const data = await Http.get('/atlas/container-type')
    return data
  }

  public async getAllContainersByShipmentId(shipmentId: string, search = '') {
    const data = await Http.get(`/atlas/shipment/${shipmentId}/container?search=${search}`)
    return data
  }

  public async getContainerById(shipmentId: number, containerId: number) {
    const data = await Http.get(`/atlas/shipment/${shipmentId}/container/${containerId}`)
    return data
  }

  public async getAllPoByShipmentIdAndContainerId(shipmentId: number, containerId: number) {
    const data = await Http.get(`/atlas/shipment/${shipmentId}/container/${containerId}/po`)
    return data
  }

  public async getSummaryByShipmentId(shipmentId: string) {
    const data = await Http.get(`/atlas/shipment/${shipmentId}/summary/load-planner`)
    return data
  }

  public async getAllUnassignedPOs(search = '') {
    const data = await Http.get(`/atlas/po/unassigned?search=${search}`)
    return data
  }

  public async getCfsStatus() {
    const data = await Http.get('/atlas/cfs-status')
    return data
  }

  public async getShippers() {
    const data = await Http.get('/atlas/shipper')
    return data
  }

  public async downloadBLInstructions(shipmentId: string) {
    const response = await Http.get(`/atlas/shipment/${shipmentId}/po/excel/list-grouped`)
    const link = document.createElement('a')
    link.href = response.data.url
    link.setAttribute('target', '_blank')
    link.click()
  }

  public async downloadCTDPlan(shipmentId: string, containerId: string) {
    const response = await Http.get(
      `/atlas/shipment/${shipmentId}/po/excel/list-by-container?containerId=${containerId}`
    )
    const link = document.createElement('a')
    link.href = response.data.url
    link.setAttribute('target', '_blank')
    link.click()
  }

  public async downloadPOReport(shipmentId: string) {
    const response = await Http.get(`/atlas/shipment/${shipmentId}/po/excel/list`)
    const link = document.createElement('a')
    link.href = response.data.url
    link.setAttribute('target', '_blank')
    link.click()
  }

  public async getOriginPort() {
    const data = await Http.get('/atlas/port/origin')
    return data
  }

  public async getDestinationPort() {
    const data = await Http.get('/atlas/port/destination')
    return data
  }

  // POST

  public async createShipment(data: { name: string; date_start: Date; date_end: Date }) {
    const response = await Http.post('/atlas/shipment', data)
    return response
  }

  public async relocatePo(shipmentId: string, poId: string, data: { srcContainerId: string; dstContainerId: string }) {
    const response = await Http.post(`/atlas/shipment/${shipmentId}/po/${poId}/relocate`, data)
    return response
  }

  public async createContainer(shipmentId: string, data: object) {
    const response = await Http.post(`/atlas/shipment/${shipmentId}/container`, data)
    return response
  }

  public async uploadContainerToSam(shipmentId: number, containerId: number) {
    const response = await Http.post(`/atlas/shipment/${shipmentId}/container/${containerId}/upload`)
    return response
  }

  public async assignPosToContainer(
    shipmentId: string,
    containerId: string,
    data: {
      poIds: string[]
    }
  ) {
    const response = await Http.post(`/atlas/shipment/${shipmentId}/container/${containerId}/po`, data)
    return response
  }

  public async uploadToSam(shipmentId: string, data: { masters: string[]; etas?: IEta[]; atd?: number }) {
    const response = await Http.post(`/atlas/shipment/${shipmentId}/upload`, data)
    return response
  }

  public async createShipper(data: { name: string; code: string; addedBy: string; added: string }) {
    const response = await Http.post('/atlas/shipper', data)
    return response
  }

  // PUT

  public async updateShipmentById(shipmentId: number, data: { name: string; date_start: Date; date_end: Date }) {
    const response = await Http.put(`/atlas/shipment/${shipmentId}`, data)
    return response
  }

  public async updatePoByShipmentId(shipmentId: number, poId: number) {
    const response = await Http.put(`/atlas/shipment/${shipmentId}/po/${poId}`)
    return response
  }

  public async updateContainerByShipmentId(
    shipmentId: string,
    containerId: string,
    data: {
      container: string
      seal: string
      master: string
      containerCfsArrival: number
      containerCfsDeparture: number
    }
  ) {
    const response = await Http.put(`/atlas/shipment/${shipmentId}/container/${containerId}`, data)
    return response
  }

  public async updatePoById(shipmentId: string, poId: string, data: Record<string, unknown>) {
    const response = await Http.put(`/atlas/shipment/${shipmentId}/po/${poId}`, data)
    return response
  }

  public async updateContainerStatus(shipmentId: string, containerId: string, data: { containerStatus: string }) {
    const response = await Http.put(`/atlas/shipment/${shipmentId}/container/${containerId}/status`, data)
    return response
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async updateUnassignedPOs(data: any) {
    const response = await Http.put('/atlas/po/update-many', data as object)
    return response
  }

  public async updateShipperById(shipperId: number, data: { name: string; code: string }) {
    const response = await Http.put(`/atlas/shipper/${shipperId}`, data)
    return response
  }

  // DELETE

  public async deleteShipmentById(shipmentId: number) {
    const response = await Http.delete(`/atlas/shipment/${shipmentId}`)
    return response
  }

  public async deleteContainerById(shipmentId: string, containerId: string) {
    const response = await Http.delete(`/atlas/shipment/${shipmentId}/container/${containerId}`)
    return response
  }

  public async removePosFromContainerId(
    shipmentId: string,
    containerId: string,
    data: {
      poIds: string[]
    }
  ) {
    const response = await Http.delete(`/atlas/shipment/${shipmentId}/container/${containerId}/po`, { data })
    return response
  }

  public async deleteShipperById(shipperId: number) {
    const response = await Http.delete(`/atlas/shipper/${shipperId}`)
    return response
  }
}

export default AtlasShipmentService.getInstance()
