/* eslint-disable @typescript-eslint/naming-convention */
import { useHttp } from '@core/hooks/useHttp'
import { useSnackbar } from '@shared/components/snackbar/hooks/useSnackbar'
import { getUserInfo, login } from '@shared/constants/auth-http'
import { APP_VERSION } from '@shared/constants/constants'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Login } from './components/Login'
import { userStore } from './store/user.store'

export const LoginContainer = () => {
  const { post, get } = useHttp()
  const { handleOpenSnackbar } = useSnackbar()
  const { setUserCredentials, setUserInfo, setSessionId, removeUserData } = userStore()
  const navigate = useNavigate()
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleShowPassword = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password')
  }

  const navigateTo = (permissions: string[]) => {
    if (permissions.includes('kaira.issue.dashboard')) {
      navigate('/', { replace: true })
    } else if (permissions.includes('kaira.order_delivery')) {
      navigate('/orders', { replace: true })
    } else if (permissions.includes('atlas.loadplanner')) {
      navigate('/atlas/load-planner', { replace: true })
    } else if (permissions.includes('atlas.warehouse')) {
      navigate('/atlas/warehouse-control', { replace: true })
    } else {
      removeUserData()
      navigate('/auth', { replace: true })
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target
    if (id === 'loginEmail') {
      setEmail(value)
    } else {
      setPassword(value)
    }
  }

  const handleAuth = async (event: any) => {
    event.preventDefault()
    const email = event.target.loginEmail.value
    const password = event.target.loginPassword.value

    try {
      if (typeof localStorage.APP_VERSION === 'undefined' || localStorage.APP_VERSION === null) {
        localStorage.setItem('APP_VERSION', APP_VERSION.toString())
      }
      if (localStorage.APP_VERSION < +APP_VERSION) {
        localStorage.clear()
        handleOpenSnackbar('New app version, please login again', 'INFO', 'warning')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
        return
      }
      setLoading(true)
      const { access_token, expires_in, refresh_token } = await post(login, { email, password })
      if (!access_token) {
        handleOpenSnackbar('Loging error', 'FAILURE', 'error')
        setLoading(false)
        return
      }
      setUserCredentials(access_token, expires_in, refresh_token)
      const { data } = await get(getUserInfo)
      data.products = data.permissions as string[]
      setUserInfo(data)
      setSessionId(uuidv4())
      navigateTo(data.products as string[])
      setLoading(false)
      handleOpenSnackbar('Loging success', 'SUCCESS', 'success')
    } catch (error) {
      removeUserData()
      // navigate('/auth', { replace: true })
      handleOpenSnackbar('Loging error', 'FAILURE', 'error')
      setLoading(false)
    }
  }
  return (
    <Login
      handleAuth={async (e) => {
        await handleAuth(e)
      }}
      handleShowPassword={handleShowPassword}
      passwordType={passwordType}
      setPasswordType={setPasswordType}
      loading={loading}
      handleInputChange={handleInputChange}
      email={email}
      password={password}
    />
  )
}
