export const SVGEyeNotVisible = () => {
  return (
    <svg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.57387 0.0826421C1.40254 -0.0504696 1.15574 -0.0194856 1.02263 0.151847C0.889517 0.323179 0.920501 0.56998 1.09183 0.703091L4.17438 3.09799C2.78033 4.01887 1.41503 5.27857 0.0866113 6.87506C-0.0288704 7.00497 -0.0288704 7.20707 0.0866113 7.33699C3.20462 11.0901 6.52472 12.9956 9.94587 12.9956H10.2958C12.1044 12.9356 13.7255 12.355 15.0992 11.5857L18.4977 14.226C18.669 14.3592 18.9158 14.3282 19.0489 14.1568C19.182 13.9855 19.151 13.7387 18.9797 13.6056L15.8207 11.1513C18.2862 9.56153 19.7975 7.4961 19.9306 7.31419L19.935 7.30811C20.0217 7.1782 20.0217 7.01941 19.935 6.88949C19.7725 6.66787 15.9336 1.43287 10.2779 1.23089C8.44894 1.17117 6.64703 1.64805 4.88812 2.65755L1.57387 0.0826421ZM4.77593 3.56534C3.43516 4.41561 2.11443 5.5981 0.822808 7.10602C3.83977 10.6427 7.00108 12.3893 10.2346 12.2594C11.7867 12.2067 13.2085 11.7279 14.4435 11.0763L13.0248 9.97408C12.2626 10.769 11.1903 11.2644 10.0036 11.2644C7.69397 11.2644 5.81739 9.38781 5.81739 7.07818C5.81739 6.26643 6.0492 5.50817 6.45014 4.86607L4.77593 3.56534ZM6.92676 4.24141L5.505 3.13682C7.06163 2.29376 8.64281 1.89554 10.2346 1.95265C14.9116 2.11143 18.3905 6.13886 19.17 7.10602C18.711 7.68619 17.2703 9.36759 15.1813 10.6545L13.5134 9.35872C13.9411 8.70235 14.1898 7.91896 14.1898 7.07818C14.1898 4.76854 12.3132 2.89196 10.0036 2.89196C8.7878 2.89196 7.692 3.41197 6.92676 4.24141ZM7.03159 5.31781C6.71916 5.8377 6.53915 6.44515 6.53915 7.09261C6.53915 8.99806 8.09816 10.5571 10.0036 10.5571C10.9596 10.5571 11.8284 10.1646 12.4569 9.53285L7.03159 5.31781ZM12.9451 8.91721L7.50947 4.69413C8.14084 4.03795 9.02669 3.62816 10.0036 3.62816C11.9091 3.62816 13.4681 5.18716 13.4681 7.09261C13.4681 7.76085 13.2763 8.38649 12.9451 8.91721Z'
        fill='#dddddd'
      />
    </svg>
  )
}
