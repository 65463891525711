export const SVGUserLogin = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.73328 4.99932C3.73328 2.46066 5.78651 0.407422 8.32518 0.407422C10.8638 0.407422 12.9171 2.46066 12.9171 4.99932C12.9171 7.53799 10.8638 9.59123 8.32518 9.59123C5.78651 9.59123 3.73328 7.53799 3.73328 4.99932Z" fill="#DDDDDD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.68328 4.99932C3.68328 2.43304 5.7589 0.357422 8.32518 0.357422C10.8915 0.357422 12.9671 2.43304 12.9671 4.99932C12.9671 7.56561 10.8915 9.64123 8.32518 9.64123C5.7589 9.64123 3.68328 7.56561 3.68328 4.99932ZM8.32518 0.457422C5.81412 0.457422 3.78328 2.48827 3.78328 4.99932C3.78328 7.51038 5.81412 9.54123 8.32518 9.54123C10.8362 9.54123 12.8671 7.51038 12.8671 4.99932C12.8671 2.48827 10.8362 0.457422 8.32518 0.457422Z" fill="#DDDDDD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.325 10.5923C10.6865 10.5923 11.7322 9.39332 11.8186 9.27502C11.9384 9.12703 12.1309 9.09519 12.3006 9.18734C14.8056 10.6192 16.35 13.2941 16.35 16.1569C16.35 16.3687 16.185 16.5338 15.9732 16.5338H15.8732V16.5528H0.676819C0.465029 16.5528 0.3 16.3878 0.3 16.176C0.3 13.2942 1.84428 10.6383 4.34935 9.20643C4.51316 9.11757 4.70885 9.15943 4.83332 9.29671C4.93288 9.42877 5.98068 10.5923 8.325 10.5923Z" fill="#DDDDDD"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.7798 9.24356C11.9159 9.07544 12.1352 9.04063 12.3245 9.1434L12.3255 9.14392C14.846 10.5847 16.4 13.2761 16.4 16.1569C16.4 16.3963 16.2126 16.5838 15.9732 16.5838H15.9232V16.6028H0.676819C0.437415 16.6028 0.25 16.4154 0.25 16.176C0.25 13.2762 1.80393 10.6038 4.32454 9.16302L4.32551 9.16247C4.51129 9.06169 4.73176 9.11026 4.87037 9.26313L4.87341 9.26649C4.9651 9.38811 5.99883 10.5423 8.325 10.5423C10.6679 10.5423 11.6996 9.35321 11.7783 9.24551L11.7798 9.24356ZM15.8232 16.5028V16.4838H15.9732C16.1574 16.4838 16.3 16.3411 16.3 16.1569C16.3 13.3122 14.7654 10.654 12.2763 9.23103C12.1266 9.15 11.9616 9.17863 11.8582 9.30561C11.759 9.44005 10.6986 10.6423 8.325 10.6423C5.97285 10.6423 4.91011 9.47952 4.79486 9.32874C4.68459 9.20843 4.5148 9.17385 4.37366 9.25012C1.88441 10.6732 0.35 13.3124 0.35 16.176C0.35 16.3602 0.492643 16.5028 0.676819 16.5028H15.8232Z" fill="#DDDDDD"/>
    </svg>
  )
}
