import { FormControl, InputAdornment, TextField } from '@mui/material'
import { KairaTypography } from '@shared/components/ui/typography/typography'
import './login.css'

interface ILoginFormItemProps {
  label: string
  id: string
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: string
  startAdornment?: JSX.Element
  endAdornment?: JSX.Element
  placeholder?: string
  isError?: boolean
}

export const LoginFormItem = ({
  label,
  id,
  handleInputChange,
  startAdornment,
  endAdornment,
  type = 'text',
  placeholder,
  isError = false
}: ILoginFormItemProps) => {
  return (
    <FormControl
      sx={{
        width: '100%',
        mb: '30px'
      }}>
      <KairaTypography variant={'typoLoginLagel'}>{label}</KairaTypography>
      <TextField
        className='login-input'
        variant='outlined'
        id={id}
        type={type}
        InputProps={{
          sx: {
            '&::placeholder': {
              color: '#dddddd',
              opacity: 1
            }
          },
          startAdornment: startAdornment != null && <InputAdornment position='start'>{startAdornment}</InputAdornment>,
          endAdornment: endAdornment != null && <InputAdornment position='end'>{endAdornment}</InputAdornment>
        }}
        onChange={handleInputChange}
        placeholder={placeholder}
        sx={{
          mt: '12px',
          border: isError ? '1px solid #E75147' : '1px solid #dddddd',
          borderRadius: '50px',
          background: 'transparent',
          width: '100%',
          height: '40px',
          color: '#dddddd',
          '& .MuiOutlinedInput-input': {
            color: '#dddddd',
            height: '0px',
            mt: '2px'
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            color: '#dddddd'
          }
        }}
      />
    </FormControl>
  )
}
