export const checkUserIsLoged = (): void => {
  const id = JSON.parse(localStorage.getItem('user-storage') ?? '{}').state.id
  if (!id || id === 0) {
    window.location.replace('/auth')
  }
}

export const checkUserPermissions = (permissonType: string): void => {
  const permissions = JSON.parse(localStorage.getItem('user-storage') ?? '{}').state.permissions
  permissions.length === 0 && window.location.replace('/auth')

  // const products = permissions.map((permission: string) => {
  //   console.log(permission)
  //   return permission.split('.')[0]
  // })
  !permissions.includes(permissonType) && window.location.replace('/auth')
}
