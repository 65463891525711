import { Box, SvgIcon } from '@mui/material'
import { SVGTriangleAlertRed } from '@shared/components/icons'
import { KairaButton } from '@shared/components/ui/button/button'
import { KairaTypography } from '@shared/components/ui/typography/typography'
import { useRef, type FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginFormItem } from '../LoginFormItem'

interface IEnterEmailProps {
  handleInputChange: (event: { target: { value: any } }) => void
  handleSend: (event: FormEvent<HTMLFormElement>) => void
  isError: boolean
  email: string
}

export const EnterEmail = ({ handleInputChange, handleSend, isError, email }: IEnterEmailProps) => {
  const { t } = useTranslation()
  const formRefEnterEmail = useRef(null)

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '540px', lg: '540px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <KairaTypography className='text-[15px] font-light leading-[22px] text-[#A2A2A2] text-center mb-[32px]'>
        {t('AUTH.FORGOT_PASSWORD_TEXT')}
      </KairaTypography>
      <form
        ref={formRefEnterEmail}
        onSubmit={(e) => {
          handleSend(e)
        }}
        style={{ width: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <LoginFormItem
          label={t('AUTH.EMAIL')}
          id='email'
          handleInputChange={handleInputChange}
          placeholder={t('AUTH.ENTER_EMAIL')}
          isError={isError}
        />
        {isError && (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: '-20px' }}>
            <SvgIcon sx={{ fontSize: '18px' }}>
              <SVGTriangleAlertRed />
            </SvgIcon>
            <KairaTypography variant={'typoLoginError'}>{t('AUTH.INVALID_EMAIL')}</KairaTypography>
          </Box>
        )}
        <KairaButton variant={'btnLogin'} className='w-[230px] h-[52px] mt-[24px]' disabled={email?.length < 3}>
          {t('AUTH.CONTINUE')}
        </KairaButton>
      </form>
    </Box>
  )
}
