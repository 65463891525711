import { snackbarStore } from '@shared/components/snackbar/store/snackbar.store'
import { KairaTypography } from '@shared/components/ui/typography/typography'

export const useSnackbar = () => {
  const {
    isSnackbarOpen,
    message,
    actionMessage,
    severity,
    setIsSnackbarOpen,
    setMessage,
    setActionMessage,
    setSeverity
  } = snackbarStore()

  const action = <KairaTypography variant={'typoSnackbarBtn'}>{actionMessage}</KairaTypography>

  const handleOpenSnackbar = (
    message: string,
    actionMessage: string,
    severity: 'success' | 'error' | 'info' | 'warning'
  ) => {
    setMessage(message)
    setActionMessage(actionMessage)
    setSeverity(severity)
    setIsSnackbarOpen(true)
  }

  return {
    message,
    actionMessage,
    severity,
    action,
    isSnackbarOpen,
    setMessage,
    setActionMessage,
    setSeverity,
    handleOpenSnackbar,
    setIsSnackbarOpen
  }
}
