import i18n, { type Resource } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import i18nService from './services/i18n.service'

const langs = ['en-US', 'es-ES']

const resources: Resource = {}

for (const lang of langs) {
  resources[lang] = await i18nService.getLoginTranslationResources(lang)
}
await i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') ?? 'en-US',
    debug: false,
    fallbackLng: localStorage.getItem('i18nextLng') ?? 'en-US',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  })
