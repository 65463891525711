/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

// interface IuserStore extends IUser {
//   accessToken: string
//   expiresIn: number
//   refreshToken: string
//   setUserInfo: (user: IUser) => void
//   setUserCredentials: (accessToken: string, expiresIn: number, refreshToken: string) => void
//   removeUserData: () => void
// }

export const userStore = create<any>(
  persist(
    (set) => ({
      id: 0,
      clientId: '',
      name: '',
      email: '',
      accessToken: '',
      expiresIn: 0,
      refreshToken: '',
      permissions: [],
      products: [],
      sessionId: '',
      setUserInfo: (user: any) => {
        set(() => ({
          id: user.id,
          name: user.name,
          email: user.email,
          permissions: user.permissions,
          products: user.products,
          clientId: user.clientId
        }))
      },
      setUserCredentials: (accessToken: any, expiresIn: any, refreshToken: any) => {
        set(() => ({
          accessToken,
          expiresIn,
          refreshToken
        }))
      },
      setSessionId: (sessionId: any) => {
        set(() => ({
          sessionId
        }))
      },
      removeUserData: () => {
        set(() => ({
          id: 0,
          name: '',
          email: '',
          accessToken: '',
          expiresIn: 0,
          refreshToken: '',
          permissions: [],
          products: [],
          sessionId: '',
          translations: {},
          clientId: ''
        }))
      }
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
