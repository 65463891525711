import { router } from '@core/router/router.ts'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-license-pro'
import { SnackBar } from '@shared/components/snackbar/Snackbar'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactDOM from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { RouterProvider } from 'react-router-dom'
import './core/i18n/login-i18n'
import { customTheme } from './styles/custom-theme'
import './styles/globals.css'

const tagManagerArgs = {
  gtmId: 'G-2XCVK01N36',
  dataLayer: {
    userId: '001',
    userProject: 'project'
  }
}

if (import.meta.env.VITE_ENVIRONMENT === 'pro') {
  TagManager.initialize(tagManagerArgs)
}

const MUI_PRO_LICENSE =
  '89a5e715f1641cee0bb13741273928aeTz05Njk3NixFPTE3NTYzNjk3OTEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=='
LicenseInfo.setLicenseKey(MUI_PRO_LICENSE)

// Sentry.init({
//   dsn: 'https://abb493762b314007ac2279d7f1e1016c@o4505583488729088.ingest.sentry.io/4505583490695168',
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       )
//     }),
//     new Sentry.Replay()
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: [
//     'localhost',
//     'https://dev-atlas.kairadigital.net',
//     'https://sta-atlas.kairadigital.net',
//     'https://kairadigital.net'
//   ],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0
// })

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ThemeProvider theme={customTheme}>
    <CssBaseline />
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
    <SnackBar />
  </ThemeProvider>
)
