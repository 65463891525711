import { Box, IconButton, Link, SvgIcon } from '@mui/material'
import { SVGEyeNotVisible, SVGEyeVisible, SVGLock, SVGTriangleAlertRed, SVGUserLogin } from '@shared/components/icons'
import { KairaSpinner } from '@shared/components/kaira-spinner/KairaSpinner'
import { KairaButton } from '@shared/components/ui/button/button'
import { KairaTypography } from '@shared/components/ui/typography/typography'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoginFormItem } from './LoginFormItem'

interface ILoginProps {
  handleAuth: (event: any) => Promise<void>
  handleShowPassword: () => void
  passwordType: string
  setPasswordType: (passwordType: string) => void
  loading: boolean
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  email: string
  password: string
}

export const Login = ({
  handleAuth,
  passwordType,
  handleShowPassword,
  loading,
  handleInputChange,
  email,
  password
}: ILoginProps) => {
  const { t } = useTranslation()
  const formRef = useRef(null)
  const [isValidEmail, setIsValidEmail] = useState(true)

  const handleValidate = async (event: any) => {
    event.preventDefault()
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isEmailValidated = pattern.test(event.target['0'].value as string)
    if (isEmailValidated) {
      setIsValidEmail(true)
      await handleAuth(event)
    } else {
      setIsValidEmail(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff',
          background: '#1e1e1e',
          backgroundImage: "url('/images/loginBackground.png')",
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Box
            component='img'
            sx={{
              height: '55px',
              width: '241px',
              marginBottom: '24px'
            }}
            src='/images/logoNegative.svg'
          />
          <KairaTypography className='font-light text-[#dddddd] text-[20px] leading-[42px] tracking-[-0.01em]'>
            {t('AUTH.LOGO_TEXT')}
          </KairaTypography>
        </Box>
        <Box
          sx={{
            width: '343px',
            marginTop: '56px',
            fontFamily: 'Inter',
            fontSize: '15px',
            lineHeight: '23px',
            letterSpacing: '0.005em'
          }}>
          <form
            ref={formRef}
            onSubmit={(e) => {
              void handleValidate(e)
            }}>
            <LoginFormItem
              label={t('AUTH.USER')}
              id='loginEmail'
              startAdornment={<SVGUserLogin />}
              handleInputChange={handleInputChange}
            />
            {!isValidEmail && (
              <Box
                sx={{
                  color: '#E75147',
                  position: 'relative',
                  top: '-24px',
                  ml: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <SvgIcon sx={{ fontSize: '16px' }}>
                  <SVGTriangleAlertRed />
                </SvgIcon>
                <KairaTypography variant={'typoLoginError'} className='ml-[2px]'>
                  {t('AUTH.INVALID_EMAIL')}
                </KairaTypography>
              </Box>
            )}
            <LoginFormItem
              label={t('AUTH.PASSWORD')}
              id='loginPassword'
              type={passwordType}
              handleInputChange={handleInputChange}
              startAdornment={<SVGLock />}
              endAdornment={
                <IconButton aria-label='toggle password visibility' onClick={handleShowPassword} edge='end'>
                  {passwordType === 'password' ? <SVGEyeVisible /> : <SVGEyeNotVisible />}
                </IconButton>
              }
            />
            <Link
              href='/password-recovery'
              sx={{
                color: '#dddddd',
                textDecoration: 'underline',
                mb: '40px',
                position: 'relative',
                top: '-18px',
                fontSize: '13px'
              }}>
              {t('AUTH.FORGOT_YOUR_PASSWORD')}
            </Link>
            <Box>
              <KairaButton
                variant={'btnLogin'}
                className='w-[100%] h-[56px] mt-[40px]'
                disabled={email?.length < 3 || password?.length < 3}>
                {t('AUTH.LOG_IN')}
              </KairaButton>
            </Box>
          </form>
        </Box>
      </Box>
      {loading && <KairaSpinner />}
    </>
  )
}
