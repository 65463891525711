/* eslint-disable @typescript-eslint/naming-convention */
import { createTheme, type Theme } from '@mui/material'

export const customTheme: Theme = createTheme({
  typography: {
    fontFamily: 'Inter, roboto, sans-serif',
    h1: {
      fontSize: '93px',
      fontWeight: 'lighter',
      letterSpacing: '-1.5px'
    },
    h2: {
      fontSize: '58px',
      fontWeight: 'lighter',
      letterSpacing: '-0.5px'
    },
    h3: {
      fontSize: '46px',
      fontWeight: 'regular',
      letterSpacing: '0px'
    },
    h4: {
      fontSize: '33px',
      fontWeight: 'regular',
      letterSpacing: '0.25px'
    },
    h5: {
      fontSize: '23px',
      fontWeight: 'regular',
      letterSpacing: '0px'
    },
    h6: {
      fontSize: '19px',
      fontWeight: 'medium',
      letterSpacing: '0.15px'
    },
    subtitle1: {
      fontSize: '15px',
      fontWeight: 'regular',
      letterSpacing: '0.15px'
    },
    subtitle2: {
      fontSize: '13px',
      fontWeight: 'regular',
      letterSpacing: '0.1px'
    },
    body1: {
      fontSize: '15px',
      fontWeight: 'regular',
      letterSpacing: '0.5px'
    },
    body2: {
      fontSize: '13px',
      fontWeight: 'regular',
      letterSpacing: '0.25px'
    },
    button: {
      fontSize: '15px',
      fontWeight: 'medium',
      letterSpacing: '0.5px'
    },
    caption: {
      fontSize: '12px',
      fontWeight: 'regular',
      letterSpacing: '1.5px'
    }
  },
  palette: {
    grey: {
      100: '#FFFFFF',
      200: '#F4F4F4',
      300: '#F2F1F1',
      400: '#DDDDDD',
      500: '#C0C0C0',
      600: '#A2A2A2',
      700: '#706F6F',
      800: '#404040',
      900: '#1E1E1E'
    },
    primary: {
      main: '#2080C3',
      200: '#E7F3FB',
      300: '#DBECF8',
      400: '#80BAE3',
      600: '#115B8E',
      800: '#063455'
    },
    secondary: {
      main: '#C6A0F3',
      200: '#F9F5FF',
      400: '#E0C9FB',
      600: '#8A6DAD',
      800: '#4F3D65'
    },
    error: {
      main: '#E75147',
      200: '#FEECEB',
      400: '#F69B95',
      600: '#A7342C',
      800: '#631B16'
    },
    info: {
      main: '#EED267',
      200: '#FFFCEC',
      400: '#FDE99B',
      600: '#B09633',
      800: '#66581A'
    },
    success: {
      main: '#42B64A',
      200: '#EBF9EC',
      400: '#94DA99',
      600: '#2A8430',
      800: '#164D1A'
    },
    warning: {
      main: '#6acbf0t',
      200: '#FFF2E9',
      300: '#FFE8D9',
      400: '#FEB889',
      600: '#B2581C',
      800: '#66320C'
    },
    text: {
      primary: '#1E1E1E',
      // Secondary: '#2080C3',
      disabled: '#DDDDDD'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
})
