import { NotFound } from '@core/layouts/NotFound/NotFound'
import { adminLoader } from '@modules/Atlas/views/adminLoader'
import { loadPlannerLoader } from '@modules/Atlas/views/loadPlannerLoader'
import { wareHouseLoader } from '@modules/Atlas/views/wareHouseLoader'
import { LoginContainer } from '@modules/Auth/LoginContainer'
import { ForgotPassword } from '@modules/Auth/components/ForgotPassword/ForgotPassword'
import { incidencesLoader } from '@modules/Kaira/views/Incidences/incidencesLoader'
import { ordersLoader } from '@modules/Kaira/views/Orders/ordersLoader'
import { overvierwLoader } from '@modules/Kaira/views/Overview/overviewLoader'
import { type RouteObject } from 'react-router-dom'

export const routes: RouteObject[] = [
  {
    path: '/auth',
    element: <LoginContainer />
  },
  {
    path: 'password-recovery',
    element: <ForgotPassword />
  },
  {
    path: 'reset-password',
    errorElement: <NotFound />,
    async lazy() {
      const { NewPasswordContainer } = await import('@modules/Auth/components/NewPassword/NewPasswordContainer')
      return { Component: NewPasswordContainer }
    }
  },
  {
    path: 'shared-order-detail',
    errorElement: <NotFound />,
    async lazy() {
      const { SharedOrderDetailContainer } = await import(
        '@modules/Kaira/views/Orders/components/OrdersDetail/SharedOrderDetailContainer'
      )
      return { Component: SharedOrderDetailContainer }
    }
  },
  {
    path: 'shared-incidence-detail',
    errorElement: <NotFound />,
    async lazy() {
      const { SharedIncidenceDetailsContainer } = await import(
        '@modules/Kaira/views/Incidences/components/IncidenceDetails/SharedIncidenceDetailsContainer'
      )
      return { Component: SharedIncidenceDetailsContainer }
    }
  },
  {
    path: 'shared-transport-detail',
    errorElement: <NotFound />,
    async lazy() {
      const { TransportDetailsContainer } = await import(
        '@modules/Kaira/views/Overview/TransportDetails/TransportDetailsContainer'
      )
      return { Component: TransportDetailsContainer }
    }
  },
  {
    path: '/',
    errorElement: <NotFound />,
    async lazy() {
      const { Navigation } = await import('@core/layouts/Navigator/Navigator')
      return { Component: Navigation }
    },
    children: [
      {
        index: true,
        loader: incidencesLoader,
        async lazy() {
          const { Incidences } = await import('@modules/Kaira/views/Incidences/Incidences')
          return { Component: Incidences }
        }
      },
      {
        path: 'incidence-details/:id',
        loader: incidencesLoader,
        async lazy() {
          const { IncidenceDetailsContainer } = await import(
            '@modules/Kaira/views/Incidences/components/IncidenceDetails/IncidenceDetailsContainer'
          )
          return { Component: IncidenceDetailsContainer }
        }
      },
      {
        path: 'incidence-details/:id/historic',
        loader: incidencesLoader,
        async lazy() {
          const { IncidenceDetailsContainer } = await import(
            '@modules/Kaira/views/Incidences/components/IncidenceDetails/IncidenceDetailsContainer'
          )
          return { Component: IncidenceDetailsContainer }
        }
      },
      {
        path: 'orders',
        loader: ordersLoader,
        async lazy() {
          const { Orders } = await import('@modules/Kaira/views/Orders/Orders')
          return { Component: Orders }
        }
      },
      {
        path: 'order-details/:id',
        loader: ordersLoader,
        async lazy() {
          const { OrderDetailContainer } = await import(
            '@modules/Kaira/views/Orders/components/OrdersDetail/OrderDetailContainer'
          )
          return { Component: OrderDetailContainer }
        }
      },
      {
        path: 'order-details/:id/historic',
        loader: ordersLoader,
        async lazy() {
          const { OrderDetailContainer } = await import(
            '@modules/Kaira/views/Orders/components/OrdersDetail/OrderDetailContainer'
          )
          return { Component: OrderDetailContainer }
        }
      },
      {
        path: 'overview',
        loader: overvierwLoader,
        async lazy() {
          const { Overview } = await import('@modules/Kaira/views/Overview/Overview')
          return { Component: Overview }
        }
      },
      {
        path: 'transport-details/:id',
        loader: ordersLoader,
        async lazy() {
          const { TransportDetailsContainer } = await import(
            '@modules/Kaira/views/Overview/TransportDetails/TransportDetailsContainer'
          )
          return { Component: TransportDetailsContainer }
        }
      },
      {
        path: 'atlas/load-planner',
        loader: loadPlannerLoader,
        async lazy() {
          const { LoadPlanner } = await import('@modules/Atlas/views/load-planner/LoadPlanner')
          return { Component: LoadPlanner }
        }
      },
      {
        path: 'atlas/warehouse-control',
        loader: wareHouseLoader,
        async lazy() {
          const { WarehouseControl } = await import('@modules/Atlas/views/warehouse/WarehouseControl')
          return { Component: WarehouseControl }
        }
      },
      {
        path: 'atlas/admin',
        // TODO: fix this
        loader: adminLoader,
        async lazy() {
          const { ShipperCreation } = await import('@modules/Atlas/views/admin/shippers/ShipperCreation')
          return { Component: ShipperCreation }
        }
      },
      {
        path: 'atlas/admin/:id',
        // TODO: fix this
        // loader: adminLoader,
        async lazy() {
          const { ShipperDetails } = await import('@modules/Atlas/views/admin/shippers/ShipperDetails')
          return { Component: ShipperDetails }
        }
      }
    ]
  }
]
