import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import './button.css'

import { cn } from '../../../utils/twMerge'

const buttonVariants = cva('', {
  variants: {
    variant: {
      btnPopup: 'btn-popup',
      btnLogin: 'btn-login',
      btnEmailSent: 'btn-email-sent',
      btnChatUI: 'btn-chatUI',
      btnDetails: 'btn-details',
      btnDetailsClicked: 'btn-details-clicked',
      btnText: 'btn-text',
      btnMapMaker: 'btn-mapMarker'
    }
  }
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const KairaButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, asChild = false, disabled = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, className }))} ref={ref} {...props} disabled={disabled} />
  }
)
KairaButton.displayName = 'KairaButton'

export { buttonVariants, KairaButton }
