import axios, { type AxiosError, type AxiosInstance, type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'

class Http {
  private static instance: Http
  private readonly http: AxiosInstance
  private isRefreshingToken: boolean = false

  private constructor() {
    const baseURL = `${String(import.meta.env.VITE_API_URL)}`

    this.http = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    this.http.interceptors.request.use(this.handleRequest, this.handleError)
    this.http.interceptors.response.use(this.handleResponse, this.handleError)
  }

  private readonly handleRequest = (config: InternalAxiosRequestConfig) => {
    const userInfo = JSON.parse(localStorage.getItem('user-storage') ?? '{}')
    if (userInfo.state.accessToken) {
      config.headers.Authorization = `Bearer ${userInfo.state.accessToken as string}`
    }
    return config
  }

  private readonly handleResponse = (response: AxiosResponse) => response

  private readonly handleError = async (error: AxiosError) => {
    if ((error.response?.status === 401 || error.response?.status === 403) && !this.isRefreshingToken) {
      this.isRefreshingToken = true
      const originalRequest = error.config!

      const userStored = JSON.parse(localStorage.getItem('user-storage') ?? '{}')
      try {
        const refreshTokenResponse = await fetch(`${String(import.meta.env.VITE_API_URL)}accounts/refresh`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            refreshToken: userStored.state.refreshToken
          })
        })
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { access_token, refresh_token } = await refreshTokenResponse.json()

        userStored.state.accessToken = access_token
        userStored.state.refreshToken = refresh_token

        localStorage.setItem('user-storage', JSON.stringify(userStored))

        originalRequest.headers.Authorization = `Bearer ${access_token}`
        return await axios(originalRequest)
      } catch (refreshError) {
        if (
          window.location.pathname !== '/auth' &&
          window.location.pathname !== 'password-recovery' &&
          window.location.pathname !== 'reset-password'
        ) {
          window.location.href = '/auth'
          return await Promise.reject(refreshError)
        }
        return
      } finally {
        this.isRefreshingToken = false
      }
    }
    return await Promise.reject(error)
  }

  public static getInstance(): Http {
    if (!Http.instance) {
      Http.instance = new Http()
    }
    return Http.instance
  }

  public async get(url: string, params?: object) {
    const response = await this.http.get(url, params)
    return response.data
  }

  public async post(url: string, body?: object) {
    const response = await this.http.post(url, body)
    return response.data
  }

  public async put(url: string, body?: object) {
    const response = await this.http.put(url, body)
    return response.data
  }

  public async delete(url: string, body?: object) {
    const response = await this.http.delete(url, body)
    return response.data
  }
}

export default Http.getInstance()
